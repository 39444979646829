import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/api_service";
import buildQueryString from "helpers/buildQueryString";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ userType, queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/users/${userType}?${queryString}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error logging in");
    }
  }
);
