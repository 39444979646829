import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "store";

const Authmiddleware = (props) => {
  const { isAuthenticated, role } = useSelector(
    (state) => state.auth
  );

  if (isAuthenticated) {
    if (props.role === role) { // Checking currnet login role and rederict to authorized pages based on the current role
      return <React.Fragment>
      {props.children}
    </React.Fragment>
    } else {
      return <Navigate to="/unauthorized" />
    }
  } else {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  }
};

export default Authmiddleware;
