import Cookies from 'js-cookie';

// Set a cookie with a given name, value (JSON data), and optional options
export const setCookie = (name, value, options = {}) => {
  Cookies.set(name, JSON.stringify(value), options);
};

// Get the value of a cookie by its name and parse it as JSON
export const getCookie = (name) => {
  const cookieValue = Cookies.get(name);
  return cookieValue ? JSON.parse(cookieValue) : null;
};

// Remove a cookie by its name
export const removeCookie = (name, options = {}) => {
  Cookies.remove(name, options);
};

// Update an existing cookie's value by its name
export const updateCookieData = (name, newValue, options = {}) => {
  const existingValue = getCookie(name);
  if (existingValue !== null) {
    const updatedValue = { ...existingValue, ...newValue }; // Merge existing and new values
    setCookie(name, updatedValue, options);
  }
};