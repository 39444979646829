import React from "react";
import { useRoutes } from "react-router-dom";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import scss
import "./assets/scss/theme.scss";

const App = () => {
  const content = useRoutes([...publicRoutes, ...authProtectedRoutes])

  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  );
};

export default App;
