import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCookie } from "helpers/cookies";
import apiService from "helpers/api_service";
import allowedRoles from "constants/roles";
import { setNotAllowedUserRoleErrorMessage } from "./reducer";

export const loginUsingEmail = createAsyncThunk(
  "auth/loginUsingEmail",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.post(`/signin/email`, payload, { baseURL: process.env.REACT_APP_API_MOBILE_BASE_URL });
      const data = response.data?.d

      if (allowedRoles[data.role]) {
        // const date = new Date();
        // date.setTime(date.getTime() + (10 * 1000));
          setCookie(
            "signinUserData",
            data,
            // { expires:date }
          ); // this also detects by middleware.js but only on first page load or each visit of any specific page
      } else {
        dispatch(setNotAllowedUserRoleErrorMessage({ message: "Role type is not allowed!" }))
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error logging in");
    }
  }
);
