// Front
import Layout from "./layout/reducer";

import { combineReducers } from "@reduxjs/toolkit";
import authReducer from './redux/auth/reducer';
import userReducer from './redux/user/reducer';

const rootReducer = combineReducers({
  // OLD FROM TEMPLATE
  Layout,

  // NEW FROM SCRATCH
  auth: authReducer,
  user: userReducer,
});

export default rootReducer;
