import HorizontalLayout from 'components/HorizontalLayout';
import VerticalLayout from 'components/VerticalLayout';
import { layoutTypes } from 'constants/layout';
import React from 'react'
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};


const ProtectedLayot = () => {
  const selectLayoutState = (state) => state.Layout;
  const LayoutProperties = createSelector(
    selectLayoutState,
      (layout) => ({
        layoutType: layout.layoutType,
      })
  );

    const {
      layoutType
  } = useSelector(LayoutProperties);

  const Layout = getLayout(layoutType);

  return (
    <Layout/>
  )
}

export default ProtectedLayot