import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  // eslint-disable-next-line
  TypedUseSelectorHook,
} from "react-redux";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== "production", // Optional: Enable Redux DevTools
});

sagaMiddleware.run(rootSaga);

// ----------------------------
// Type for useSelector state autocompletion without TypeScript
/**@type TypedUseSelectorHook<ReturnType<typeof store.getState>> */
export const useSelector = useReduxSelector;

/**@type typeof store.dispatch */
export const useDispatch = () => useReduxDispatch();

export default store;