import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import InputField from "components/UI/Forms/InputField";
import { useDispatch } from "store";
import { useSelector } from "store";
import { loginUsingEmail } from "store/redux/auth/actions";
import AlertMessage from "components/UI/AlertMessage";
import allowedRoles from "constants/roles";
import Loading from "components/UI/Loading";
import withRouter from "components/UI/Common/withRouter";
import justVibingLogoLight from "../../assets/images/just-vibing-logo-light.png";

//actions
import {
  changeLayoutMode
} from "store/actions";

const Login = props => {
  //meta title
  document.title = "Login CMS";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      // email: '',
      // password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUsingEmail(values));
    }
  });

  const { error, isLoading, isAuthenticated, role } = useSelector(
    (state) => state.auth
  );
  const { layoutModeType } = useSelector(
    (state) => state.Layout
  );

  useEffect(() => {
    if (isAuthenticated && allowedRoles[role] === role) {
      navigate(`/${allowedRoles[role]}/dashboard`);
    }
  }, [isAuthenticated, navigate, role]);

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [dispatch, layoutModeType]);

  return (
    <React.Fragment>
      <Loading isLoading={isLoading} text="Logging In..."/>
      <div className=" my-5 pt-sm-5" style={{ height: "95vh"}}>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={8}>
              <AlertMessage
                color="danger"
                icon={
                  <svg 
                    width="20" 
                    height="20" 
                    viewBox="0 0 20 20" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.8891 7.52513C14.2796 7.1346 14.2796 6.50144 13.8891 6.11091C13.4986 5.72039 12.8654 5.72039 12.4749 6.11091L13.8891 7.52513ZM6.11091 12.4749C5.72039 12.8654 5.72039 13.4986 6.11091 13.8891C6.50144 14.2796 7.1346 14.2796 7.52513 13.8891L6.11091 12.4749ZM12.4749 13.8891C12.8654 14.2796 13.4986 14.2796 13.8891 13.8891C14.2796 13.4986 14.2796 12.8654 13.8891 12.4749L12.4749 13.8891ZM7.52513 6.11091C7.1346 5.72039 6.50144 5.72039 6.11091 6.11091C5.72039 6.50144 5.72039 7.1346 6.11091 7.52513L7.52513 6.11091ZM18 10C18 14.4183 14.4183 18 10 18V20C15.5228 20 20 15.5228 20 10H18ZM10 18C5.58172 18 2 14.4183 2 10H0C0 15.5228 4.47715 20 10 20V18ZM2 10C2 5.58172 5.58172 2 10 2V0C4.47715 0 0 4.47715 0 10H2ZM10 2C14.4183 2 18 5.58172 18 10H20C20 4.47715 15.5228 0 10 0V2ZM12.4749 6.11091L9.29289 9.29289L10.7071 10.7071L13.8891 7.52513L12.4749 6.11091ZM9.29289 9.29289L6.11091 12.4749L7.52513 13.8891L10.7071 10.7071L9.29289 9.29289ZM13.8891 12.4749L10.7071 9.29289L9.29289 10.7071L12.4749 13.8891L13.8891 12.4749ZM10.7071 9.29289L7.52513 6.11091L6.11091 7.52513L9.29289 10.7071L10.7071 9.29289Z" fill="#F93E63"/>
                  </svg>
                }
                isShow={error}
                message={error}
              />
              <Card className="overflow-hidden">
                <div 
                  // style={{ background: "#C6F9FF" }}
                  // style={{ background: "#282828" }}
                  // style={{ background: "#0A9AAA" }}
                >
                  <Row className="mx-auto text-center">
                    <Col className="col-12">
                      <div className="text-black p-4">
                        <h1>Welcome To CMS Backoffice!</h1>
                        <h3 className="text-muted mb-3">Sign in to continue</h3>
                        <img src={justVibingLogoLight} alt="" className="img-fluid" width={200} />
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody>
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >

                      <div className="mb-3">
                        <InputField
                          formik={formik}
                          isRequired
                          type="email"
                          label="Username"
                          name="email"
                          placeholder="Enter Username"
                          style={{ height: 54 }}
                        />
                      </div>

                      <div className="mb-3">
                        <InputField
                          formik={formik}
                          isRequired
                          type="password"
                          label="Password"
                          name="password"
                          placeholder="Enter Password"
                          style={{ height: 54 }}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          style={{ backgroundColor: "#0A9AAA", border: "none", height: 51 }}
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("facebook");
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>
                          <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("google");
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
