import React, { lazy, Suspense } from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Register from "../pages/Authentication/Register"
import Login from "../pages/Authentication/Login"
import Loading from "components/UI/Loading"
import Pages404 from "pages/Utility/Pages404"
import Authmiddleware from "./route"
import allowedRoles from "constants/roles"
import ProtectedLayot from "components/templates/ProtectedLayot"
import PublicLayout from "components/templates/PublicLayout"

// eslint-disable-next-line react/display-name
const Loadable = Component => props =>
  (
    <Suspense fallback={<Loading isLoading={true} />}>
      <Component {...props} />
    </Suspense>
  )

// {/* ADMIN IMPORT START HERE */}
const AdminDashboardPage = Loadable(
  lazy(() => import("pages/Admin/Dashboard/index"))
)
const AdminLiveStreamingPage = Loadable(
  lazy(() => import("pages/Admin/LiveStreaming/index"))
)
const AdminAgenciesPage = Loadable(
  lazy(() => import("pages/Admin/Agencies/index"))
)
const AdminViewAgencyPage = Loadable(
  lazy(() => import("pages/Admin/Agencies/[id]/index"))
)
const AdminStreamersPage = Loadable(
  lazy(() => import("pages/Admin/Streamers/index"))
)
const AdminViewStreamerPage = Loadable(
  lazy(() => import("pages/Admin/Streamers/[id]/index"))
)
const AdminOrganizationsPage = Loadable(
  lazy(() => import("pages/Admin/Organizations/index"))
)
const AdminViewOrganizationPage = Loadable(
  lazy(() => import("pages/Admin/Organizations/[id]/index"))
)
const AdminPayoutPage = Loadable(
  lazy(() => import("pages/Admin/Payout/index"))
)
const AdminChatsPage = Loadable(lazy(() => import("pages/Admin/Chats/index")))
const AdminNotificationSettingsPage = Loadable(
  lazy(() => import("pages/Admin/NotificationSettings/index"))
)
const AdminAiAndAnalyticsPage = Loadable(
  lazy(() => import("pages/Admin/AiAndAnalytics/index"))
)
const AdminSecurityAndPrivacyPage = Loadable(
  lazy(() => import("pages/Admin/SecurityAndPrivacy/index"))
)
// const AdminGiftsAndMonetizationPage = Loadable(
//   lazy(() => import("pages/Admin/GiftsAndMonetization/index"))
// )
// const AdminTrendingStreamsPage = Loadable(
//   lazy(() => import("pages/Admin/TrendingStreams/index"))
// )
// const AdminFlagContentsPage = Loadable(
//   lazy(() => import("pages/Admin/FlagContents/index"))
// )
const AdminUserAccountsPage = Loadable(
  lazy(() => import("pages/Admin/UserAccounts/index"))
)
// const AdminPollsAndQaPage = Loadable(
//   lazy(() => import("pages/Admin/PollsAndQa/index"))
// )
// const AdminGvCloudPage = Loadable(
//   lazy(() => import("pages/Admin/GvCloud/index"))
// )
// const AdminVirtualEventsPage = Loadable(
//   lazy(() => import("pages/Admin/VirtualEvents/index"))
// )
// const SelecRole = Loadable(lazy(() => import("pages/Authentication/SelecRole")));
// const OrganizationCompleteInformation = Loadable(lazy(() => import("pages/Authentication/OrganizationCompleteInformation")));
// {/* ADMIN IMPORT END HERE */}

// {/* ORGANIZATION IMPORT START HERE */}
const OrganizationDashboardPage = Loadable(
  lazy(() => import("pages/Organization/Dashboard/index"))
)
const OrganizationAgenciesPage = Loadable(
  lazy(() => import("pages/Organization/Agencies/index"))
)
const OrganizationViewAgencyPage = Loadable(
  lazy(() => import("pages/Organization/Agencies/[id]/index"))
)

const OrganizationStreamersPage = Loadable(
  lazy(() => import("pages/Organization/Streamers/index"))
)
// {/* ORGANIZATION IMPORT END HERE */}

// {/* AGENCY IMPORT START HERE */}
const AgencyDashboardPage = Loadable(
  lazy(() => import("pages/Agency/Dashboard/index"))
)
const AgencyStreamersPage = Loadable(
  lazy(() => import("pages/Agency/Streamers/index"))
)
const AgencyViewStreamerPage = Loadable(
  lazy(() => import("pages/Agency/Streamers/[id]/index"))
)
// {/* AGENCY IMPORT END HERE */}

// {/* STREAMER IMPORT START HERE */}
const StreamerDashboardPage = Loadable(
  lazy(() => import("pages/Streamer/Dashboard/index"))
)

// {/* STREAMER IMPORT END HERE */}

// {/* ADMIN ROUTE START HERE */}
const adminRoutes = {
  path: "admin",
  element: (
    <Authmiddleware role={allowedRoles["admin"]}>
      <ProtectedLayot />
    </Authmiddleware>
  ),
  children: [
    {
      path: "dashboard",
      children: [{ path: "", element: <AdminDashboardPage /> }],
    },
    {
      path: "chats",
      children: [{ path: "", element: <AdminChatsPage /> }],
    },
    {
      path: "notifications-settings",
      children: [{ path: "", element: <AdminNotificationSettingsPage /> }],
    },
    {
      path: "ai-and-analytics",
      children: [{ path: "", element: <AdminAiAndAnalyticsPage /> }],
    },
    {
      path: "security-and-privacy",
      children: [{ path: "", element: <AdminSecurityAndPrivacyPage /> }],
    },
    {
      path: "agencies",
      children: [
        {
          path: "",
          element: <AdminAgenciesPage />,
        },
        {
          path: ":id",
          element: <AdminViewAgencyPage />,
        },
      ],
    },
    {
      path: "streamers",
      children: [
        {
          path: "",
          element: <AdminStreamersPage />,
        },
        {
          path: ":id",
          element: <AdminViewStreamerPage />,
        },
      ],
    },
    {
      path: "organizations",
      children: [
        {
          path: "",
          element: <AdminOrganizationsPage />,
        },
        {
          path: ":id",
          element: <AdminViewOrganizationPage />,
        },
      ],
    },
    {
      path: "payout",
      children: [
        {
          path: "",
          element: <AdminPayoutPage />,
        },
      ],
    },
    
    // {
    //   path: "live-streaming",
    //   children: [{ path: "", element: <AdminLiveStreamingPage /> }],
    // },
    // {
    //   path: "gifts-and-monetization",
    //   children: [{ path: "", element: <AdminLiveStreamingPage /> }],
    // },
    // {
    //   path: "trending-streams",
    //   children: [{ path: "", element: <AdminTrendingStreamsPage /> }],
    // },
    // {
    //   path: "flag-contents",
    //   children: [{ path: "", element: <AdminFlagContentsPage /> }],
    // },
    {
      path: "user-accounts",
      children: [{ path: "", element: <AdminUserAccountsPage /> }],
    },
    // {
    //   path: "polls-and-qa",
    //   children: [{ path: "", element: <AdminPollsAndQaPage /> }],
    // },
    // {
    //   path: "gv-cloud",
    //   children: [{ path: "", element: <AdminGvCloudPage /> }],
    // },
    // {
    //   path: "virtual-events",
    //   children: [{ path: "", element: <AdminVirtualEventsPage /> }],
    // },
  ],
}
// {/* ADMIN ROUTE END HERE */}

// {/* ORGANIZATION ROUTE START HERE */}
const organizationRoutes = {
  path: "organization",
  element: (
    <Authmiddleware role={allowedRoles["organization"]}>
      <ProtectedLayot />
    </Authmiddleware>
  ),
  children: [
    {
      path: "dashboard",
      children: [
        {
          path: "",
          element: <OrganizationDashboardPage />,
        },
      ],
    },
    {
      path: "agencies",
      children: [
        {
          path: "",
          element: <OrganizationAgenciesPage />,
        },
        {
          path: ":id",
          element: <OrganizationViewAgencyPage />,
        },
      ],
    },
    {
      path: "streamers",
      children: [
        {
          path: "",
          element: <OrganizationStreamersPage />,
        },
        // {
        //   path: ":id",
        //   element: <OrganizationViewAgencyPage />,
        // },
      ],
    },
  ],
}
// {/* ORGANIZATION ROUTE END HERE */}

// {/* AGENCY ROUTE START HERE */}
const agencyRoutes = {
  path: "agency",
  element: (
    <Authmiddleware role={allowedRoles["agency"]}>
      <ProtectedLayot />
    </Authmiddleware>
  ),
  children: [
    {
      path: "dashboard",
      children: [
        {
          path: "",
          element: <AgencyDashboardPage />,
        },
      ],
    },
    {
      path: "streamers",
      children: [
        {
          path: "",
          element: <AgencyStreamersPage />,
        },
        {
          path: ":id",
          element: <AgencyViewStreamerPage />,
        },
      ],
    },
  ],
}
// {/* AGENCY ROUTE END HERE */}

// {/* STREAMER ROUTE START HERE */}
const streamerRoutes = {
  path: "streamer",
  element: (
    <Authmiddleware role={allowedRoles["streamer"]}>
      <ProtectedLayot />
    </Authmiddleware>
  ),
  children: [
    {
      path: "dashboard",
      children: [
        {
          path: "",
          element: <StreamerDashboardPage />,
        },
      ],
    },
  ],
}
// {/* STREAMER ROUTE END HERE */}

// {/* PUBLIC ROUTE START HERE */}
const publicRoutes = [
  {
    path: "login",
    element: <PublicLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "*",
    element: <PublicLayout />,
    children: [
      {
        path: "", // equivalent to 👉 / to fix react-router bug
        element: <Navigate to="/login" />,
      },
      {
        path: "unauthorized",
        element: <div>Unathorized</div>,
      },
      {
        path: "*",
        element: <Pages404 />,
      },
    ],
  },
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
]
// {/* PUBLIC ROUTE END HERE */}

const authProtectedRoutes = [
  adminRoutes,
  organizationRoutes,
  agencyRoutes,
  streamerRoutes,
]

export { publicRoutes, authProtectedRoutes }
